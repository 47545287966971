import React, { useContext, useState, useEffect } from 'react';
import axios from 'axios';
import { navigate, Link } from 'gatsby';
import { Input, Tooltip, Table, Button, message, Alert } from 'antd';
import { PaperClipOutlined, DeleteOutlined } from '@ant-design/icons';
import moment from 'moment';

import SEO from '../blocks/layout/seo';
import AppContainer from '../blocks/layout';
import { Firebase } from '../config/firebase';
import AccountDeletion from '../blocks/codes/accountDeletion';
import { getAPICountWithName } from '../config/custom/functions';
import { GlobalStateContext } from '../context/GlobalContextProvider';

import '../styles/project-settings.scss';

let custID = '';

const makeUserPortalLink = () => {
      message.info('Loading...');
      const data = JSON.stringify({ customer_id: custID });

      const config = {
            method: 'post',
            url: `${process.env.GATSBY_BASE_URL}/customer_portal_link`,
            headers: {
                  'Content-Type': 'application/json',
            },
            data,
      };

      axios(config)
            .then(function (response) {
                  const { url } = response.data;
                  navigate(url);
            })
            .catch(function (error) {
                  console.log(error);
            });
};

const invoiceColumns = [
      {
            title: 'Invoice Email',
            dataIndex: 'customer_email',
            key: 'customer_email',
      },
      {
            title: 'Invoice Date',
            dataIndex: 'created',
            key: 'created',
            render: (created) => (
                  <span>{moment.unix(created).format('DD MMM YYYY')}</span>
            ),
      },
      {
            title: 'Status',
            dataIndex: 'paid',
            key: 'paid',
            render: (paid) => <span>{paid ? 'paid' : 'un-paid'}</span>,
      },
      {
            title: 'Period',
            dataIndex: 'hosted_invoice_url',
            key: 'hosted_invoice_url',
            render: (invoiceURL) => (
                  <Button
                        icon={<PaperClipOutlined />}
                        href={invoiceURL}
                        target="_blank"
                        style={{
                              fontSize: 13,
                              fontWeight: 400,
                        }}
                  >
                        View Invoice
                  </Button>
            ),
      },
];
// markup
const AccountSettings = () => {
      const state = useContext(GlobalStateContext);
      const { user } = state;
      const [loading, setLoading] = useState(false);
      const [userSubscriptions, setUserSubscriptions] = useState(null);
      const [customerInvoices, setCustomerInvoices] = useState(null);

      useEffect(() => {
            setLoading(true);
            async function fetchUserSubscriptions(userId) {
                  try {
                        const subscriptionsRes = await axios.get(
                              `${process.env.GATSBY_BASE_URL}/subscriptions/${userId}`
                        );
                        const customerInvoicesRes = await axios.get(
                              `${process.env.GATSBY_BASE_URL}/customerInvoices/${userId}`
                        );
                        custID = subscriptionsRes.data.data[0].customer;
                        if (subscriptionsRes.data.message === undefined)
                              setUserSubscriptions(
                                    subscriptionsRes.data.data[0]
                              );
                        if (customerInvoicesRes.data.message === undefined)
                              setCustomerInvoices(customerInvoicesRes.data);
                        if (
                              subscriptionsRes.data.message !== undefined &&
                              customerInvoicesRes.data.message !== undefined
                        )
                              setLoading(false);
                  } catch (error) {
                        setLoading(false);
                  }
            }
            Firebase.auth().onAuthStateChanged((user) => {
                  if (user) {
                        fetchUserSubscriptions(user.uid);
                  }
            });
      }, [user]);

      const resetAPIKey = async () => {
            const { cloud_name, uid } = user;
            message.info('Resetting your API key.');
            setTimeout(() => {
                  axios.post(
                        `${process.env.GATSBY_BASE_URL}/resetAPIKey`,
                        { cloud_name, uid },
                        {
                              headers: { 'Content-Type': 'application/json' },
                        }
                  )
                        .then((res) => {
                              // console.log(res)
                              message.success('Your NoCodeAPI API key changed');
                              window.location.reload();
                        })
                        .catch((error) => {
                              setLoading(false);
                              message.info('There are some issues.');
                        });
            }, 2000);
      };

      return (
            <>
                  <SEO title="Account Settings — NoCodeAPI" />
                  <AppContainer activeNav="settings">
                        <div className="page-content-header">
                              <h2>Account Settings</h2>
                              <p>
                                    Your accounts, billing, plans, & more
                                    information.
                              </p>
                        </div>
                        {user !== null && user !== undefined && (
                              <div className="project-edit">
                                    <p>
                                          <b>
                                                <u>Credentials</u>
                                          </b>
                                    </p>
                                    <label>Cloud name(username)</label>
                                    <Input
                                          defaultValue={user.cloud_name}
                                          disabled
                                    />
                                    <br />
                                    <label>API key</label>
                                    <br />
                                    <Input.Password
                                          defaultValue={user.api_key}
                                          type="password"
                                          disabled
                                    />
                                    <p className="input-info">
                                          This API key only will be used when
                                          you secure your API otherwise you
                                          don't need this API key. You can also
                                          reset this API key at any time.{' '}
                                          <a onClick={resetAPIKey}>Reset now</a>
                                    </p>
                                    <br />
                                    <label>Email address</label>
                                    <Input defaultValue={user.email} disabled />
                                    <br />
                                    <br />
                                    <p>
                                          <b>
                                                <u>Subscription</u>
                                          </b>
                                    </p>
                                    {userSubscriptions !== null ? (
                                          <>
                                                <p>
                                                      Your active subscription
                                                      is{' '}
                                                      <Tooltip
                                                            title={`${getAPICountWithName(
                                                                  userSubscriptions
                                                                        .plan
                                                                        .metadata
                                                                        .plan
                                                            )} API requests per month`}
                                                      >
                                                            <b>
                                                                  <u>
                                                                        <span>
                                                                              {
                                                                                    userSubscriptions
                                                                                          .plan
                                                                                          .metadata
                                                                                          .plan
                                                                              }
                                                                        </span>
                                                                  </u>
                                                            </b>
                                                      </Tooltip>{' '}
                                                      Plan.
                                                </p>
                                                <p>
                                                      Want's to do any changes
                                                      with current plan? Like{' '}
                                                      <b>Update plan</b>,{' '}
                                                      <b>
                                                            Update payment
                                                            method
                                                      </b>
                                                      ,{' '}
                                                      <b>Billing information</b>
                                                      , or{' '}
                                                      <b>
                                                            cancel current
                                                            subscription
                                                      </b>
                                                      .
                                                </p>
                                                <p>
                                                <Button
                                                      type="primary"
                                                      style={{
                                                            fontSize: 13,
                                                            fontWeight: 400,
                                                      }}
                                                      onClick={() =>
                                                            makeUserPortalLink()
                                                      }
                                                >
                                                      Update current
                                                      subscription
                                                </Button>
                                                </p>
                                                <p>
                                                <Button
                                                      type="ghost"
                                                      style={{
                                                            fontSize: 13,
                                                            fontWeight: 400,
                                                      }}
                                                      onClick={() =>
                                                            makeUserPortalLink()
                                                      }
                                                >
                                                      Cancel Subscription
                                                </Button>
                                                </p>       
                                          </>
                                    ) : (
                                          <>
                                                <p>
                                                      You don't have any active
                                                      subscriptions.
                                                </p>
                                                <Link to="/plans">
                                                      <Button
                                                            type="primary"
                                                            style={{
                                                                  fontSize: 13,
                                                                  fontWeight: 400,
                                                            }}
                                                      >
                                                            Subscribe now
                                                      </Button>
                                                </Link>
                                          </>
                                    )}
                                    <br />
                                    <br />
                                    <br />
                                    <br />
                                    <p>
                                          <b>
                                                <u>Invoices</u>
                                          </b>
                                    </p>
                                    {customerInvoices !== null ? (
                                          <Table
                                                pagination={false}
                                                tableLayout="fixed"
                                                className="domain-list"
                                                columns={invoiceColumns}
                                                dataSource={
                                                      customerInvoices.data
                                                }
                                                rowKey={(obj) => obj.created}
                                          />
                                    ) : (
                                          <p>
                                                There are no past invoices
                                                available.
                                          </p>
                                    )}

                                    <br />
                                    <br />
                                    <AccountDeletion user={user} />
                              </div>
                        )}
                  </AppContainer>
            </>
      );
};

export default AccountSettings;
